/*
  Google material colors
 */

//## Red
$mdc-red-50: #ffebee;
$mdc-red-100: #ffcdd2;
$mdc-red-200: #ef9a9a;
$mdc-red-300: #e57373;
$mdc-red-400: #ef5350;
$mdc-red-500: #f44336;
$mdc-red-600: #e53935;
$mdc-red-700: #d32f2f;
$mdc-red-800: #c62828;
$mdc-red-900: #b71c1c;
$mdc-red-A100: #ff8a80;
$mdc-red-A200: #ff5252;
$mdc-red-A400: #ff1744;
$mdc-red-A700: #d50000;

//## Pink
$mdc-pink-50: #fce4ec;
$mdc-pink-100: #f8bbd0;
$mdc-pink-200: #f48fb1;
$mdc-pink-300: #f06292;
$mdc-pink-400: #ec407a;
$mdc-pink-500: #e91e63;
$mdc-pink-600: #d81b60;
$mdc-pink-700: #c2185b;
$mdc-pink-800: #ad1457;
$mdc-pink-900: #880e4f;
$mdc-pink-A100: #ff80ab;
$mdc-pink-A200: #ff4081;
$mdc-pink-A400: #f50057;
$mdc-pink-A700: #c51162;

//## Purple
$mdc-purple-50: #f3e5f5;
$mdc-purple-100: #e1bee7;
$mdc-purple-200: #ce93d8;
$mdc-purple-300: #ba68c8;
$mdc-purple-400: #ab47bc;
$mdc-purple-500: #9c27b0;
$mdc-purple-600: #8e24aa;
$mdc-purple-700: #7b1fa2;
$mdc-purple-800: #6a1b9a;
$mdc-purple-900: #4a148c;
$mdc-purple-A100: #ea80fc;
$mdc-purple-A200: #e040fb;
$mdc-purple-A400: #d500f9;
$mdc-purple-A700: #aa00ff;

//## Deep Purple
$mdc-deep-purple-50: #ede7f6;
$mdc-deep-purple-100: #d1c4e9;
$mdc-deep-purple-200: #b39ddb;
$mdc-deep-purple-300: #9575cd;
$mdc-deep-purple-400: #7e57c2;
$mdc-deep-purple-500: #673ab7;
$mdc-deep-purple-600: #5e35b1;
$mdc-deep-purple-700: #512da8;
$mdc-deep-purple-800: #4527a0;
$mdc-deep-purple-900: #311b92;
$mdc-deep-purple-A100: #b388ff;
$mdc-deep-purple-A200: #7c4dff;
$mdc-deep-purple-A400: #651fff;
$mdc-deep-purple-A700: #6200ea;

//## Indigo
$mdc-indigo-50: #e8eaf6;
$mdc-indigo-100: #c5cae9;
$mdc-indigo-200: #9fa8da;
$mdc-indigo-300: #7986cb;
$mdc-indigo-400: #5c6bc0;
$mdc-indigo-500: #3f51b5;
$mdc-indigo-600: #3949ab;
$mdc-indigo-700: #303f9f;
$mdc-indigo-800: #283593;
$mdc-indigo-900: #1a237e;
$mdc-indigo-A100: #8c9eff;
$mdc-indigo-A200: #536dfe;
$mdc-indigo-A400: #3d5afe;
$mdc-indigo-A700: #304ffe;

//## Blue
$mdc-blue-50: #e3f2fd;
$mdc-blue-100: #bbdefb;
$mdc-blue-200: #90caf9;
$mdc-blue-300: #64b5f6;
$mdc-blue-400: #42a5f5;
$mdc-blue-500: #2196f3;
$mdc-blue-600: #1e88e5;
$mdc-blue-700: #1976d2;
$mdc-blue-800: #1565c0;
$mdc-blue-900: #0d47a1;
$mdc-blue-A100: #82b1ff;
$mdc-blue-A200: #448aff;
$mdc-blue-A400: #2979ff;
$mdc-blue-A700: #2962ff;

//## Light Blue
$mdc-light-blue-50: #e1f5fe;
$mdc-light-blue-100: #b3e5fc;
$mdc-light-blue-200: #81d4fa;
$mdc-light-blue-300: #4fc3f7;
$mdc-light-blue-400: #29b6f6;
$mdc-light-blue-500: #03a9f4;
$mdc-light-blue-600: #039be5;
$mdc-light-blue-700: #0288d1;
$mdc-light-blue-800: #0277bd;
$mdc-light-blue-900: #01579b;
$mdc-light-blue-A100: #80d8ff;
$mdc-light-blue-A200: #40c4ff;
$mdc-light-blue-A400: #00b0ff;
$mdc-light-blue-A700: #0091ea;

//## Cyan
$mdc-cyan-50: #e0f7fa;
$mdc-cyan-100: #b2ebf2;
$mdc-cyan-200: #80deea;
$mdc-cyan-300: #4dd0e1;
$mdc-cyan-400: #26c6da;
$mdc-cyan-500: #00bcd4;
$mdc-cyan-600: #00acc1;
$mdc-cyan-700: #0097a7;
$mdc-cyan-800: #00838f;
$mdc-cyan-900: #006064;
$mdc-cyan-A100: #84ffff;
$mdc-cyan-A200: #18ffff;
$mdc-cyan-A400: #00e5ff;
$mdc-cyan-A700: #00b8d4;

//## Teal
$mdc-teal-50: #e0f2f1;
$mdc-teal-100: #b2dfdb;
$mdc-teal-200: #80cbc4;
$mdc-teal-300: #4db6ac;
$mdc-teal-400: #26a69a;
$mdc-teal-500: #009688;
$mdc-teal-600: #00897b;
$mdc-teal-700: #00796b;
$mdc-teal-800: #00695c;
$mdc-teal-900: #004d40;
$mdc-teal-A100: #a7ffeb;
$mdc-teal-A200: #64ffda;
$mdc-teal-A400: #1de9b6;
$mdc-teal-A700: #00bfa5;

//## Green
$mdc-green-50: #e8f5e9;
$mdc-green-100: #c8e6c9;
$mdc-green-200: #a5d6a7;
$mdc-green-300: #81c784;
$mdc-green-400: #66bb6a;
$mdc-green-500: #4caf50;
$mdc-green-600: #43a047;
$mdc-green-700: #388e3c;
$mdc-green-800: #2e7d32;
$mdc-green-900: #1b5e20;
$mdc-green-A100: #b9f6ca;
$mdc-green-A200: #69f0ae;
$mdc-green-A400: #00e676;
$mdc-green-A700: #00c853;

//## Light Green
$mdc-light-green-50: #f1f8e9;
$mdc-light-green-100: #dcedc8;
$mdc-light-green-200: #c5e1a5;
$mdc-light-green-300: #aed581;
$mdc-light-green-400: #9ccc65;
$mdc-light-green-500: #8bc34a;
$mdc-light-green-600: #7cb342;
$mdc-light-green-700: #689f38;
$mdc-light-green-800: #558b2f;
$mdc-light-green-900: #33691e;
$mdc-light-green-A100: #ccff90;
$mdc-light-green-A200: #b2ff59;
$mdc-light-green-A400: #76ff03;
$mdc-light-green-A700: #64dd17;

//## Lime
$mdc-lime-50: #f9fbe7;
$mdc-lime-100: #f0f4c3;
$mdc-lime-200: #e6ee9c;
$mdc-lime-300: #dce775;
$mdc-lime-400: #d4e157;
$mdc-lime-500: #cddc39;
$mdc-lime-600: #c0ca33;
$mdc-lime-700: #afb42b;
$mdc-lime-800: #9e9d24;
$mdc-lime-900: #827717;
$mdc-lime-A100: #f4ff81;
$mdc-lime-A200: #eeff41;
$mdc-lime-A400: #c6ff00;
$mdc-lime-A700: #aeea00;

//## Yellow
$mdc-yellow-50: #fffde7;
$mdc-yellow-100: #fff9c4;
$mdc-yellow-200: #fff59d;
$mdc-yellow-300: #fff176;
$mdc-yellow-400: #ffee58;
$mdc-yellow-500: #ffeb3b;
$mdc-yellow-600: #fdd835;
$mdc-yellow-700: #fbc02d;
$mdc-yellow-800: #f9a825;
$mdc-yellow-900: #f57f17;
$mdc-yellow-A100: #ffff8d;
$mdc-yellow-A200: #ffff00;
$mdc-yellow-A400: #ffea00;
$mdc-yellow-A700: #ffd600;

//## Amber
$mdc-amber-50: #fff8e1;
$mdc-amber-100: #ffecb3;
$mdc-amber-200: #ffe082;
$mdc-amber-300: #ffd54f;
$mdc-amber-400: #ffca28;
$mdc-amber-500: #ffc107;
$mdc-amber-600: #ffb300;
$mdc-amber-700: #ffa000;
$mdc-amber-800: #ff8f00;
$mdc-amber-900: #ff6f00;
$mdc-amber-A100: #ffe57f;
$mdc-amber-A200: #ffd740;
$mdc-amber-A400: #ffc400;
$mdc-amber-A700: #ffab00;

//## Orange
$mdc-orange-50: #fff3e0;
$mdc-orange-100: #ffe0b2;
$mdc-orange-200: #ffcc80;
$mdc-orange-300: #ffb74d;
$mdc-orange-400: #ffa726;
$mdc-orange-500: #ff9800;
$mdc-orange-600: #fb8c00;
$mdc-orange-700: #f57c00;
$mdc-orange-800: #ef6c00;
$mdc-orange-900: #e65100;
$mdc-orange-A100: #ffd180;
$mdc-orange-A200: #ffab40;
$mdc-orange-A400: #ff9100;
$mdc-orange-A700: #ff6d00;

//## Deep Orange
$mdc-deep-orange-50: #fbe9e7;
$mdc-deep-orange-100: #ffccbc;
$mdc-deep-orange-200: #ffab91;
$mdc-deep-orange-300: #ff8a65;
$mdc-deep-orange-400: #ff7043;
$mdc-deep-orange-500: #ff5722;
$mdc-deep-orange-600: #f4511e;
$mdc-deep-orange-700: #e64a19;
$mdc-deep-orange-800: #d84315;
$mdc-deep-orange-900: #bf360c;
$mdc-deep-orange-A100: #ff9e80;
$mdc-deep-orange-A200: #ff6e40;
$mdc-deep-orange-A400: #ff3d00;
$mdc-deep-orange-A700: #dd2c00;

//## Brown
$mdc-brown-50: #efebe9;
$mdc-brown-100: #d7ccc8;
$mdc-brown-200: #bcaaa4;
$mdc-brown-300: #a1887f;
$mdc-brown-400: #8d6e63;
$mdc-brown-500: #795548;
$mdc-brown-600: #6d4c41;
$mdc-brown-700: #5d4037;
$mdc-brown-800: #4e342e;
$mdc-brown-900: #3e2723;

//## Grey
$mdc-grey-50: #fafafa;
$mdc-grey-100: #f5f5f5;
$mdc-grey-200: #eeeeee;
$mdc-grey-300: #e0e0e0;
$mdc-grey-400: #bdbdbd;
$mdc-grey-500: #9e9e9e;
$mdc-grey-600: #757575;
$mdc-grey-700: #616161;
$mdc-grey-800: #424242;
$mdc-grey-900: #212121;

//## Blue Grey
$mdc-blue-grey-50: #eceff1;
$mdc-blue-grey-100: #cfd8dc;
$mdc-blue-grey-200: #b0bec5;
$mdc-blue-grey-300: #90a4ae;
$mdc-blue-grey-400: #78909c;
$mdc-blue-grey-500: #607d8b;
$mdc-blue-grey-600: #546e7a;
$mdc-blue-grey-700: #455a64;
$mdc-blue-grey-800: #37474f;
$mdc-blue-grey-900: #263238;

//== Alpha values for grey text, icons, and dividers

//## White
$mdc-white-lighter: rgba(255, 255, 255, 0.12); // Dividers
$mdc-white-light: rgba(255, 255, 255, 0.3); // Disabled / Hint Text
$mdc-white-dark: rgba(255, 255, 255, 0.7); // Secondary Text
$mdc-white-darker: rgba(255, 255, 255, 1); // Text / Icons

//## Black
$mdc-black-lighter: rgba(0, 0, 0, 0.12); // Dividers
$mdc-black-light: rgba(0, 0, 0, 0.26); // Disabled / Hint Text
$mdc-black-dark: rgba(0, 0, 0, 0.54); // Secondary text / Icons
$mdc-black-darker: rgba(0, 0, 0, 0.87); // Text

// Material color background generation

// Colors list
$md-colors: (
  //## Red
  'red-50': $mdc-red-50,
  'red-100': $mdc-red-100,
  'red-200': $mdc-red-200,
  'red-300': $mdc-red-300,
  'red-400': $mdc-red-400,
  'red-500': $mdc-red-500,
  'red-600': $mdc-red-600,
  'red-700': $mdc-red-700,
  'red-800': $mdc-red-800,
  'red-900': $mdc-red-900,
  'red-A100': $mdc-red-A100,
  'red-A200': $mdc-red-A200,
  'red-A400': $mdc-red-A400,
  'red-A700': $mdc-red-A700,
  //## Pink
  'pink-50': $mdc-pink-50,
  'pink-100': $mdc-pink-100,
  'pink-200': $mdc-pink-200,
  'pink-300': $mdc-pink-300,
  'pink-400': $mdc-pink-400,
  'pink-500': $mdc-pink-500,
  'pink-600': $mdc-pink-600,
  'pink-700': $mdc-pink-700,
  'pink-800': $mdc-pink-800,
  'pink-900': $mdc-pink-900,
  'pink-A100': $mdc-pink-A100,
  'pink-A200': $mdc-pink-A200,
  'pink-A400': $mdc-pink-A400,
  'pink-A700': $mdc-pink-A700,
  //## Purple
  'purple-50': $mdc-purple-50,
  'purple-100': $mdc-purple-100,
  'purple-200': $mdc-purple-200,
  'purple-300': $mdc-purple-300,
  'purple-400': $mdc-purple-400,
  'purple-500': $mdc-purple-500,
  'purple-600': $mdc-purple-600,
  'purple-700': $mdc-purple-700,
  'purple-800': $mdc-purple-800,
  'purple-900': $mdc-purple-900,
  'purple-A100': $mdc-purple-A100,
  'purple-A200': $mdc-purple-A200,
  'purple-A400': $mdc-purple-A400,
  'purple-A700': $mdc-purple-A700,
  //## Deep Purple
  'deep-purple-50': $mdc-deep-purple-50,
  'deep-purple-100': $mdc-deep-purple-100,
  'deep-purple-200': $mdc-deep-purple-200,
  'deep-purple-300': $mdc-deep-purple-300,
  'deep-purple-400': $mdc-deep-purple-400,
  'deep-purple-500': $mdc-deep-purple-500,
  'deep-purple-600': $mdc-deep-purple-600,
  'deep-purple-700': $mdc-deep-purple-700,
  'deep-purple-800': $mdc-deep-purple-800,
  'deep-purple-900': $mdc-deep-purple-900,
  'deep-purple-A100': $mdc-deep-purple-A100,
  'deep-purple-A200': $mdc-deep-purple-A200,
  'deep-purple-A400': $mdc-deep-purple-A400,
  'deep-purple-A700': $mdc-deep-purple-A700,
  //## Indigo
  'indigo-50': $mdc-indigo-50,
  'indigo-100': $mdc-indigo-100,
  'indigo-200': $mdc-indigo-200,
  'indigo-300': $mdc-indigo-300,
  'indigo-400': $mdc-indigo-400,
  'indigo-500': $mdc-indigo-500,
  'indigo-600': $mdc-indigo-600,
  'indigo-700': $mdc-indigo-700,
  'indigo-800': $mdc-indigo-800,
  'indigo-900': $mdc-indigo-900,
  'indigo-A100': $mdc-indigo-A100,
  'indigo-A200': $mdc-indigo-A200,
  'indigo-A400': $mdc-indigo-A400,
  'indigo-A700': $mdc-indigo-A700,
  //## Blue
  'blue-50': $mdc-blue-50,
  'blue-100': $mdc-blue-100,
  'blue-200': $mdc-blue-200,
  'blue-300': $mdc-blue-300,
  'blue-400': $mdc-blue-400,
  'blue-500': $mdc-blue-500,
  'blue-600': $mdc-blue-600,
  'blue-700': $mdc-blue-700,
  'blue-800': $mdc-blue-800,
  'blue-900': $mdc-blue-900,
  'blue-A100': $mdc-blue-A100,
  'blue-A200': $mdc-blue-A200,
  'blue-A400': $mdc-blue-A400,
  'blue-A700': $mdc-blue-A700,
  //## Light Blue
  'light-blue-50': $mdc-light-blue-50,
  'light-blue-100': $mdc-light-blue-100,
  'light-blue-200': $mdc-light-blue-200,
  'light-blue-300': $mdc-light-blue-300,
  'light-blue-400': $mdc-light-blue-400,
  'light-blue-500': $mdc-light-blue-500,
  'light-blue-600': $mdc-light-blue-600,
  'light-blue-700': $mdc-light-blue-700,
  'light-blue-800': $mdc-light-blue-800,
  'light-blue-900': $mdc-light-blue-900,
  'light-blue-A100': $mdc-light-blue-A100,
  'light-blue-A200': $mdc-light-blue-A200,
  'light-blue-A400': $mdc-light-blue-A400,
  'light-blue-A700': $mdc-light-blue-A700,
  //## Cyan
  'cyan-50': $mdc-cyan-50,
  'cyan-100': $mdc-cyan-100,
  'cyan-200': $mdc-cyan-200,
  'cyan-300': $mdc-cyan-300,
  'cyan-400': $mdc-cyan-400,
  'cyan-500': $mdc-cyan-500,
  'cyan-600': $mdc-cyan-600,
  'cyan-700': $mdc-cyan-700,
  'cyan-800': $mdc-cyan-800,
  'cyan-900': $mdc-cyan-900,
  'cyan-A100': $mdc-cyan-A100,
  'cyan-A200': $mdc-cyan-A200,
  'cyan-A400': $mdc-cyan-A400,
  'cyan-A700': $mdc-cyan-A700,
  //## Teal
  'teal-50': $mdc-teal-50,
  'teal-100': $mdc-teal-100,
  'teal-200': $mdc-teal-200,
  'teal-300': $mdc-teal-300,
  'teal-400': $mdc-teal-400,
  'teal-500': $mdc-teal-500,
  'teal-600': $mdc-teal-600,
  'teal-700': $mdc-teal-700,
  'teal-800': $mdc-teal-800,
  'teal-900': $mdc-teal-900,
  'teal-A100': $mdc-teal-A100,
  'teal-A200': $mdc-teal-A200,
  'teal-A400': $mdc-teal-A400,
  'teal-A700': $mdc-teal-A700,
  //## Green
  'green-50': $mdc-green-50,
  'green-100': $mdc-green-100,
  'green-200': $mdc-green-200,
  'green-300': $mdc-green-300,
  'green-400': $mdc-green-400,
  'green-500': $mdc-green-500,
  'green-600': $mdc-green-600,
  'green-700': $mdc-green-700,
  'green-800': $mdc-green-800,
  'green-900': $mdc-green-900,
  'green-A100': $mdc-green-A100,
  'green-A200': $mdc-green-A200,
  'green-A400': $mdc-green-A400,
  'green-A700': $mdc-green-A700,
  //## Light Green
  'light-green-50': $mdc-light-green-50,
  'light-green-100': $mdc-light-green-100,
  'light-green-200': $mdc-light-green-200,
  'light-green-300': $mdc-light-green-300,
  'light-green-400': $mdc-light-green-400,
  'light-green-500': $mdc-light-green-500,
  'light-green-600': $mdc-light-green-600,
  'light-green-700': $mdc-light-green-700,
  'light-green-800': $mdc-light-green-800,
  'light-green-900': $mdc-light-green-900,
  'light-green-A100': $mdc-light-green-A100,
  'light-green-A200': $mdc-light-green-A200,
  'light-green-A400': $mdc-light-green-A400,
  'light-green-A700': $mdc-light-green-A700,
  //## Lime
  'lime-50': $mdc-lime-50,
  'lime-100': $mdc-lime-100,
  'lime-200': $mdc-lime-200,
  'lime-300': $mdc-lime-300,
  'lime-400': $mdc-lime-400,
  'lime-500': $mdc-lime-500,
  'lime-600': $mdc-lime-600,
  'lime-700': $mdc-lime-700,
  'lime-800': $mdc-lime-800,
  'lime-900': $mdc-lime-900,
  'lime-A100': $mdc-lime-A100,
  'lime-A200': $mdc-lime-A200,
  'lime-A400': $mdc-lime-A400,
  'lime-A700': $mdc-lime-A700,
  //## Yellow
  'yellow-50': $mdc-yellow-50,
  'yellow-100': $mdc-yellow-100,
  'yellow-200': $mdc-yellow-200,
  'yellow-300': $mdc-yellow-300,
  'yellow-400': $mdc-yellow-400,
  'yellow-500': $mdc-yellow-500,
  'yellow-600': $mdc-yellow-600,
  'yellow-700': $mdc-yellow-700,
  'yellow-800': $mdc-yellow-800,
  'yellow-900': $mdc-yellow-900,
  'yellow-A100': $mdc-yellow-A100,
  'yellow-A200': $mdc-yellow-A200,
  'yellow-A400': $mdc-yellow-A400,
  'yellow-A700': $mdc-yellow-A700,
  //## Amber
  'amber-50': $mdc-amber-50,
  'amber-100': $mdc-amber-100,
  'amber-200': $mdc-amber-200,
  'amber-300': $mdc-amber-300,
  'amber-400': $mdc-amber-400,
  'amber-500': $mdc-amber-500,
  'amber-600': $mdc-amber-600,
  'amber-700': $mdc-amber-700,
  'amber-800': $mdc-amber-800,
  'amber-900': $mdc-amber-900,
  'amber-A100': $mdc-amber-A100,
  'amber-A200': $mdc-amber-A200,
  'amber-A400': $mdc-amber-A400,
  'amber-A700': $mdc-amber-A700,
  //## Orange
  'orange-50': $mdc-orange-50,
  'orange-100': $mdc-orange-100,
  'orange-200': $mdc-orange-200,
  'orange-300': $mdc-orange-300,
  'orange-400': $mdc-orange-400,
  'orange-500': $mdc-orange-500,
  'orange-600': $mdc-orange-600,
  'orange-700': $mdc-orange-700,
  'orange-800': $mdc-orange-800,
  'orange-900': $mdc-orange-900,
  'orange-A100': $mdc-orange-A100,
  'orange-A200': $mdc-orange-A200,
  'orange-A400': $mdc-orange-A400,
  'orange-A700': $mdc-orange-A700,
  //## Deep Orange
  'deep-orange-50': $mdc-deep-orange-50,
  'deep-orange-100': $mdc-deep-orange-100,
  'deep-orange-200': $mdc-deep-orange-200,
  'deep-orange-300': $mdc-deep-orange-300,
  'deep-orange-400': $mdc-deep-orange-400,
  'deep-orange-500': $mdc-deep-orange-500,
  'deep-orange-600': $mdc-deep-orange-600,
  'deep-orange-700': $mdc-deep-orange-700,
  'deep-orange-800': $mdc-deep-orange-800,
  'deep-orange-900': $mdc-deep-orange-900,
  'deep-orange-A100': $mdc-deep-orange-A100,
  'deep-orange-A200': $mdc-deep-orange-A200,
  'deep-orange-A400': $mdc-deep-orange-A400,
  'deep-orange-A700': $mdc-deep-orange-A700,
  //## Brown
  'brown-50': $mdc-brown-50,
  'brown-100': $mdc-brown-100,
  'brown-200': $mdc-brown-200,
  'brown-300': $mdc-brown-300,
  'brown-400': $mdc-brown-400,
  'brown-500': $mdc-brown-500,
  'brown-600': $mdc-brown-600,
  'brown-700': $mdc-brown-700,
  'brown-800': $mdc-brown-800,
  'brown-900': $mdc-brown-900,
  //## Grey
  'grey-50': $mdc-grey-50,
  'grey-100': $mdc-grey-100,
  'grey-200': $mdc-grey-200,
  'grey-300': $mdc-grey-300,
  'grey-400': $mdc-grey-400,
  'grey-500': $mdc-grey-500,
  'grey-600': $mdc-grey-600,
  'grey-700': $mdc-grey-700,
  'grey-800': $mdc-grey-800,
  'grey-900': $mdc-grey-900,
  //## Blue Grey
  'blue-grey-50': $mdc-blue-grey-50,
  'blue-grey-100': $mdc-blue-grey-100,
  'blue-grey-200': $mdc-blue-grey-200,
  'blue-grey-300': $mdc-blue-grey-300,
  'blue-grey-400': $mdc-blue-grey-400,
  'blue-grey-500': $mdc-blue-grey-500,
  'blue-grey-600': $mdc-blue-grey-600,
  'blue-grey-700': $mdc-blue-grey-700,
  'blue-grey-800': $mdc-blue-grey-800,
  'blue-grey-900': $mdc-blue-grey-900,
  //== Alpha values for grey text, icons, and dividers
  //## White
  'white-lighter': $mdc-white-lighter,
  // Dividers
  'white-light': $mdc-white-light,
  // Disabled / Hint Text
  'white-dark': $mdc-white-dark,
  // Secondary Text
  'white-darker': $mdc-white-darker,
  // Text / Icons
  //## Black
  'black-lighter': $mdc-black-lighter,
  // Dividers
  'black-light': $mdc-black-light,
  // Disabled / Hint Text
  'black-dark': $mdc-black-dark,
  // Secondary text / Icons
  'black-darker': $mdc-black-darker // Text
);

//= Mixins

@function mdcolor($key) {
  @return map-get($md-colors, $key);
}

@each $name, $color in $md-colors {
  .bg-#{$name} {
    background-color: $color;
  }
}

// White color for some backgrounds
.bg-red-800,
.bg-red-900,
.bg-pink-700,
.bg-pink-800,
.bg-pink-900,
.bg-purple,
.bg-purple-500,
.bg-purple-600,
.bg-purple-700,
.bg-purple-800,
.bg-purple-900,
.bg-deep-purple,
.bg-deep-purple-500,
.bg-deep-purple-600,
.bg-deep-purple-700,
.bg-deep-purple-800,
.bg-deep-purple-900,
.bg-deep-purple-A400,
.bg-deep-purple-A700,
.bg-indigo,
.bg-indigo-500,
.bg-indigo-600,
.bg-indigo-700,
.bg-indigo-800,
.bg-indigo-900,
.bg-indigo-A700,
.bg-blue-800,
.bg-blue-900,
.bg-light-blue-900,
.bg-cyan-900,
.bg-teal-800,
.bg-teal-900,
.bg-green-800,
.bg-green-900,
.bg-brown,
.bg-brown-500,
.bg-brown-600,
.bg-brown-700,
.bg-brown-800,
.bg-brown-900,
.bg-blue-grey-600,
.bg-blue-grey-700,
.bg-blue-grey-800,
.bg-blue-grey-900,
.bg-grey-600,
.bg-grey-700,
.bg-grey-800,
.bg-grey-900 {
  color: rgba(255, 255, 255, 0.87);
}

.bg-red,
.bg-red-500,
.bg-red-600,
.bg-red-700,
.bg-red-A200,
.bg-red-A400,
.bg-red-A700,
.bg-pink,
.bg-pink-500,
.bg-pink-600,
.bg-pink-A200,
.bg-pink-A400,
.bg-pink-A700,
.bg-purple-300,
.bg-purple-400,
.bg-purple-A200,
.bg-purple-A400,
.bg-purple-A700,
.bg-deep-purple-300,
.bg-deep-purple-400,
.bg-deep-purple-A200,
.bg-indigo-300,
.bg-indigo-400,
.bg-indigo-A200,
.bg-indigo-A400,
.bg-blue,
.bg-blue-500,
.bg-blue-600,
.bg-blue-700,
.bg-blue-A200,
.bg-blue-A400,
.bg-blue-A700,
.bg-light-blue,
.bg-light-blue-500,
.bg-light-blue-600,
.bg-light-blue-700,
.bg-light-blue-800,
.bg-light-blue-A700,
.bg-cyan,
.bg-cyan-500,
.bg-cyan-600,
.bg-cyan-700,
.bg-cyan-800,
.bg-teal,
.bg-teal-500,
.bg-teal-600,
.bg-teal-700,
.bg-green,
.bg-green-500,
.bg-green-600,
.bg-green-700,
.bg-light-green-800,
.bg-light-green-900,
.bg-lime-900,
.bg-orange-800,
.bg-orange-900,
.bg-deep-orange,
.bg-deep-orange-500,
.bg-deep-orange-600,
.bg-deep-orange-700,
.bg-deep-orange-800,
.bg-deep-orange-900,
.bg-deep-orange-A400,
.bg-deep-orange-A700,
.bg-brown-300,
.bg-brown-400,
.bg-blue-grey,
.bg-blue-grey-400,
.bg-blue-grey-500 {
  color: #fff;
}
