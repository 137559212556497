/* You can add global styles to this file, and also import other style files */

//== Fonts
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

//== Vendor
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';

@import '~simple-line-icons/css/simple-line-icons.css';

@import '~ngx-toastr/toastr';

//== Bootstrap
@import './app/shared/styles/bootstrap.scss';
//== Application
@import './app/shared/styles/app.scss';

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~jsoneditor/dist/jsoneditor.min.css';
textarea.jsoneditor-text {
  min-height: 500px;
}
