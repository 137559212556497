.header-enroll {
  height: 60px;
  z-index: 9;
}

.content-enroll {
  margin-top: 75px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 34px;
  font-size: 12px;
  padding: 7px 24px;
}
