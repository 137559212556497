.mda-checkbox,
.mda-radio {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin: 0;
  cursor: pointer;

  input {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    margin-left: -20px;
    cursor: pointer;
    opacity: 0;
    &:checked + em:before {
      background-color: inherit;
      border-width: 0;
    }

    &:checked + span .active {
      display: inherit;
    }
  }

  // Checkbox
  input[type='checkbox']:checked + em:after {
    position: absolute;
    top: 2px;
    left: 6px;
    display: table;
    width: 6px;
    height: 12px;
    border: 2px solid;
    border-top: 0;
    border-left: 0;
    content: ' ';
    transform: rotate(45deg);
  }
  // Radio
  input[type='radio']:checked + em:after {
    content: ' ';
    position: absolute;
    top: 3px;
    left: 3px;
    display: table;
    width: 12px;
    height: 12px;
    background: inherit;
    border-radius: 50%;
    border: 2px solid #fff;
  }

  input[type='radio'] + em,
  input[type='radio'] + em:before {
    border-radius: 50%;
  }
}

// Disable states
.mda-checkbox input[disabled] + em:before,
fieldset[disabled] .mda-checkbox input + em:before,
.mda-radio input[disabled] + em:before,
fieldset[disabled] .mda-radio input + em:before {
  border-color: rgba(0, 0, 0, 0.26);
}
// disable background color for icon
.mda-checkbox input[disabled]:checked + em,
.mda-radio input[disabled]:checked + em {
  background-color: rgba(0, 0, 0, 0.26);
}

.mda-radio input[disabled]:checked + em:after {
  background-color: transparent;
}

.mda-checkbox input[disabled]:checked + em:before {
  background-color: rgba(0, 0, 0, 0.26);
}

// Icon style
.mda-checkbox > em,
.mda-radio > em {
  position: relative;
  display: inline-block;
  width: 0;
  height: 18px;
  margin-top: -2px;
  margin-right: 28px;
  margin-left: -20px;
  line-height: 1;
  vertical-align: middle;
  &:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 2px;
    @include transition(all 0.2s);
  }
  // Empty box with not icons
  &.empty:after {
    display: none !important;
  }
}

.mat-divider {
  border-top-color: #d8d8d8 !important;
  opacity: 0.1;
}

.top-layer {
  position: relative;
  z-index: 2;
}

mat-label {
  font-family: 'Rajdhani', sans-serif !important;
  font-weight: 500;
}

.mat-form-field {
  display: block !important;
  font-size: 16px !important;
}

.break-word {
  word-break: break-all;
}

.search-title {
  font-size: 16px;
  font-weight: 600 !important;
  font-family: 'Rajdhani', sans-serif;
}

.search-preview {
  line-height: 21px;
  padding-left: 40px;
  position: relative;
  top: -12px;
  .preview-namespace {
    font-weight: 500 !important;
    font-family: 'Source Sans Pro', sans-serif !important;
  }
}

.md-search.mat-form-field-prefix,
.mat-form-field-suffix {
  color: rgba(255, 255, 255, 0.5);
}

.mat-select-panel {
  background: #343242;
}

// .md-enroll.mat-form-field-appearance-outline .mat-form-field-outline {
//       background: rgba(255,255,255,0.03);
//       color: currentColor;
//   }

// .md-enroll.mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button, .mat-datepicker-toggle {
//     color: currentColor !important;
// }

// .md-enroll.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
//     background: currentColor;
// }

// .md-enroll.mat-input-element {
//   color: currentColor;
//   caret-color: currentColor;
// }

// .md-enroll.mat-option.mat-selected:not(.mat-option-disabled) {
//   color: #FFF;
// }

.line-height-filter {
  line-height: 1px !important;
}

// .mat-search_icon-close, .mat-search_icon-search {
//   color: #33CBCB;
// }

// .md-enroll {
//   .mat-option {
//     color: currentColor;
//     background: currentColor;

//     &:nth-child(odd) {
//       background: currentColor;
//     }

//     .mat-active {
//       background: currentColor;
//     }
//   }
//   .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
//     color: currentColor;
//   }
// }

@include media-breakpoint-down(sm) {
  .mat-form-field,
  .form-control {
    font-size: 14px !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background: rgba(255, 255, 255, 0.03);
  color: #52436e;
  border-radius: 5px;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.8em 0.5em;
}

.mat-input-element {
  position: relative;
  top: -4px;
}

.mat-option {
  color: #fff;
  background: #343242;

  &:nth-child(odd) {
    background: #383746;
  }
}

.mat-option.mat-active {
  color: #fff;
  background: #2a2837;
}

.mat-autocomplete-panel {
  background: #2a2837;
}

.mat-select-value {
  position: relative;
  top: -4px;
  color: #ffffff;
}

.mat-select-placeholder,
.mat-placeholder {
  color: #b0addc;
}

.mat-select-panel {
  max-height: 380px !important;
}

.mat-input-sort {
  .mat-form-field {
    font-size: 13px !important;
  }
  .mat-label {
    color: #fff;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    margin: 0 !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 0 !important;
  }

  .mat-form-field-infix {
    padding: 0.2em 0.2em !important;
  }

  .mat-form-field-flex {
    padding: 0 0.75em 0 0.5em !important  ;
  }

  .mat-select-value {
    font-size: 14px !important;
  }
}

.mat-filter-sort {
  .mat-form-field {
    font-size: 13px !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    margin: 0 !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 0 !important;
  }

  .mat-form-field-infix {
    padding: 0.2em 0.2em !important;
    top: 1px !important;
  }

  .mat-input-element {
    font-size: 14px;
  }

  .mat-form-field-flex {
    padding: 0 0.75em 0.2em 0.5em !important  ;
  }

  .mat-select-value {
    font-size: 14px !important;
  }
}

.dropdown-menu {
  border: none;
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #1d1c27;
}
