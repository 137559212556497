.swal-title {
  font-size: 24px !important;
  font-family: 'Rajdhani', sans-serif;
}

.swal-button {
  line-height: 36px;
  padding: 5px 18px 4px 18px;

  &:focus {
    box-shadow: none !important;
  }
}

.swal-button.swal-button--viewMyEnrolments,
.swal-button--enrolForAsset {
  font-size: 16px !important;
  font-family: 'Rajdhani', sans-serif !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  margin: 0 3px;
}

.swal-button--cancel {
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Rajdhani', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
.swal-button--confirm {
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Rajdhani', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

@include media-breakpoint-down(sm) {
  .swal-title {
    font-size: 21px !important;
  }

  .swal-text {
    font-size: 14px !important;
  }

  .swal-button--cancel,
  .swal-button--confirm {
    font-size: 14px !important;
  }

  .swal-button {
    padding: 0 18px 0 18px !important;
  }
}

.swal-footer {
  display: flex;
  justify-content: center;
  text-align: right;
  padding-top: 0;
  margin: 20px 20px 5px 20px;
  padding: 13px 16px;
  flex-wrap: wrap;
}
